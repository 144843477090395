import React from 'react';
import '../styles/our-mission.css'
import { NoBorderBtn } from './home-buttons';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";

const OurMission = () => {
  const {t} = useTranslation();

  return (
    <>
      <div className="container our-mission-ctn">

        <StaticImage
          className="circle-motive-our-mission"
          src="../../../images/home/theme-circles/circles-elements-our-mission.png"
          alt="Circle motive our mission"
          placeholder="none"
          loading="lazy"
        />

        <div className="our-mission-text-ctn">
          <h3>{t`home.our-mission`}</h3>
          <p className="quotations mb-1rem">{t`home.our-mission-test`}</p>
          <div className="bosses mb-1rem">
            <p>{t`home.kacper`} <span>{t`home.boss1`}</span> <span className="bullet">•</span> </p>
            <p>{t`home.jakub`} <span>{t`home.boss2`}</span> <span className="bullet">•</span> </p>
            <p>{t`home.nina`} <span>{t`home.boss3`}</span></p>
          </div>
          <NoBorderBtn to="/about-us">
            {t`home.meet-inhub`}&nbsp;&nbsp;
            <FontAwesomeIcon icon={faArrowRight} />
          </NoBorderBtn>
        </div>

        <div className="our-mission-media-ctn">
          <StaticImage
            src="../../../images/home/raciborski.jpg"
            alt="Kacper Raciborski - InHub official photo"
            placeholder="blurred"
            loading="lazy"
            quality={100}
            className="initiative-img profile-img profile-img-1"
          />
          <StaticImage
            src="../../../images/home/borowy.jpg"
            alt="Kuba Borowy - InHub official photo"
            placeholder="blurred"
            loading="lazy"
            quality={100}
            className="initiative-img profile-img profile-img-2"
          />
          <StaticImage
            src="../../../images/home/badura.jpg"
            alt="Nina Badura - InHub official photo"
            placeholder="blurred"
            loading="lazy"
            quality={100}
            className="initiative-img profile-img profile-img-3"
          />
        </div>
      </div>
    </>
  );
}

export default OurMission;
