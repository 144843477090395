import React from 'react';
import '../styles/our-services.css'
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const OurServices = () => {
  const {t} = useTranslation();

  return (
    <>
      <div className="container our-service-ctn">
        <h2 className="our-services-title">{t`home.our-services`}</h2>
        <div className="services-grid">
          <div>
            <StaticImage
              src="../../../images/home/our-services/dev.png"
              alt="Software development icon"
              placeholder="none"
              loading="lazy"
            />
            <h4 className="mb-1rem">{t`home.service1`}</h4>
            <p>{t`home.service1-text`}</p>
          </div>
          <div>
            <StaticImage
              src="../../../images/home/our-services/graphic.png"
              alt="Graphic and video icon"
              placeholder="none"
              loading="lazy"
            />
            <h4 className="mb-1rem">{t`home.service2`}</h4>
            <p>{t`home.service2-text`}</p>
          </div>
          <div>
            <StaticImage
              src="../../../images/home/our-services/marketing.png"
              alt="Software development icon"
              placeholder="none"
              loading="lazy"
            />
            <h4 className="mb-1rem">{t`home.service3`}</h4>
            <p>{t`home.service3-text`}</p>
          </div>
          <div>
            <StaticImage
              src="../../../images/home/our-services/fund.png"
              alt="Software development icon"
              placeholder="none"
              loading="lazy"
            />
            <h4 className="mb-1rem">{t`home.service4`}</h4>
            <p>{t`home.service4-text`}</p>
          </div>
          <div>
            <StaticImage
              src="../../../images/home/our-services/project-mining.png"
              alt="Software development icon"
              placeholder="none"
              loading="lazy"
            />
            <h4 className="mb-1rem">{t`home.service5`}</h4>
            <p>{t`home.service5-text`}</p>
          </div>
          <div>
            <StaticImage
              src="../../../images/home/our-services/hr.png"
              alt="Software development icon"
              placeholder="none"
              loading="lazy"
            />
            <h4 className="mb-1rem">{t`home.service6`}</h4>
            <p>{t`home.service6-text`}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurServices;
