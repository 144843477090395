import React from 'react';
import '../styles/inhub-is-people.css'
import { NoBorderBtn } from './home-buttons';
import Video from '../../../components/video';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";

const InhubIsPeople = () => {
  const {t} = useTranslation();

  return (
    <>
      <div className="container ppl-ctn">
        <div className="ppl-video-ctn">
          <Video src="https://www.youtube.com/embed/wWDIn2v3I4k" title="About MedBiz"/>
        </div>

        <div className="ppl-text-ctn">
          <h2>{t`home.we-ppl`}</h2>
          <p className="mb-1rem">{t`home.we-ppl-text`}</p>
          <NoBorderBtn to="/team">{t`home.meet-our-team`}&nbsp;&nbsp;
            <FontAwesomeIcon icon={faArrowRight} /></NoBorderBtn>
        </div>
      </div>
    </>
  );
}

export default InhubIsPeople
