import { StaticImage } from 'gatsby-plugin-image';
import React, { useContext } from 'react';
import { BorderBtn, FullBtn } from './home-buttons';
import '../styles/interact-with-us.css';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ModalContext } from '../../../contexts/ModalContext';

const InteractWithUs = () => {
  const { setIsDonateModalOpen } = useContext(ModalContext);
  const { t } = useTranslation();

  return (
    <>
      <div className="container interact-with-us-ctn">
        <div className="write-text-ctn">
          <h2>{t`home.write-to-us`}</h2>
          <p>{t`home.question`} </p>
          <p>{t`home.do-u-want-to-work-with-us`} </p>
          <p>{t`home.join-us`} </p>
          <p style={{ marginBottom: '2rem' }}>{t`home.write-to-us`} </p>
          <FullBtn to="/contact">{t`home.write-to-us_`}</FullBtn>
        </div>
        <StaticImage
          src="../../../images/home/write-to-us.png"
          alt="Ikonka napisz do nas"
          placeholder="none"
          loading="lazy"
        />
      </div>

      <div className="container support-us-ctn">
        <h2>{t`home.support-us`}</h2>
        <p className="support-us-text">{t`home.support-us-text`} 🙏🏻</p>
        <div className="support-us-inner-ctn">
          <div className="support-us-border-ctn">
            <p>
              {t`home.support-us-1`} <span>{t`home.support-us-2`} </span>{' '}
              {t`home.support-us-3`}{' '}
            </p>

            <div
              className="btn border-btn"
              onClick={() => setIsDonateModalOpen(true)}>
              <a>{t`home.learn-more`}</a>
            </div>
          </div>
          <div className="support-us-border-ctn">
            <p>
              {t`home.support-us-1`} <span>{t`home.support-us-4`}</span>{' '}
              {t`home.support-us-5`} <span>{t`home.support-us-6`}</span>.
            </p>
            <BorderBtn to="/partners">{t`home.learn-more`}</BorderBtn>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: 'white' }}>
        <div className="container innovative-ctn">
          <div className="innovative-text">
            <h2>{t`home.innovation1`}</h2>
            <h2>{t`home.innovation2`}</h2>
          </div>
          <StaticImage
            src="../../../images/home/banner_koniec.jpg"
            alt="We make innovations happen"
            placeholder="blurred"
            loading="lazy"
            className="innovative-img"
          />
        </div>
      </div>
    </>
  );
};

export default InteractWithUs;
