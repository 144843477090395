import React from 'react';
import './home.css';
import HomeLandingHeader from './components/home-landing-header';
import InhubInitiatives from './components/inhub-initiatives';
import InhubsThreePerspectives from './components/inhubs-three-perspectives';
import OurMission from './components/our-mission';
import InhubIsPeople from './components/inhub-is-people';
import OurServices from './components/our-services';
import Testimonials from './components/testimonials';
import MalonowskiTestimonial from './components/malonowski-testimonial';
import InteractWithUs from './components/interact-with-us';
import Seo from '../../components/seo';
import ogImage from '../../images/seo/SEO_fundacja.jpg';
import Layout from '../../components/layout/layout';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <Layout withHeader={false} darkTheme={true}>
      <Seo
        title={t`home.page-title`}
        description={t`home.page-description`}
        ogImage={ogImage}
      />
      <div className="home-ctn">
        <HomeLandingHeader />
        <InhubInitiatives />
        <InhubsThreePerspectives />
        <OurMission />
        <InhubIsPeople />
        <OurServices />
        <Testimonials />
        <MalonowskiTestimonial />
        <InteractWithUs />
      </div>
    </Layout>
  );
};

export default HomePage;
