// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import React from 'react';
import '../styles/testimonials.css'
import nextArrow from "../../../images/home/testimonials/next-arrow.png";
import SlickSlider from "react-slick";
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';


const Testimonials = () => {
  const {t} = useTranslation();

  return (
    <>
      <div className="container testimonial-ctn">
        <h2>{t`home.write-ab-us`}</h2>
        <div>
          <Slider slidesToShow={3} slideToShowB2={1}>
            <a className="one-testimonial-ctn" href="https://mycompanypolska.pl/artykul/nurt-innowacji-fundacja-innovations-hub-ksztaltuje-nowe-pokolenie-polskich-start-upow/12244" target="_blank" rel="noreferrer nofollow">
              <p>{t`home.testimonial7`}</p>
              <StaticImage
                src="../../../images/home/testimonials/my-company-polska.png"
                alt="My Company Polska napisali o nas"
                placeholder="none"
                loading="lazy"
                className="company-logo"
              />
            </a>
            <a className="one-testimonial-ctn" href="https://mamstartup.pl/fundacja-innovations-hub-tworzy-od-podstaw-ekosystem-startupow-mlodych-ludzi/" target="_blank" rel="noreferrer nofollow">
              <p>{t`home.testimonial8`}</p>
              <StaticImage
                src="../../../images/home/testimonials/mam-startup.png"
                alt="Mam Startup napisali o nas"
                placeholder="none"
                loading="lazy"
                className="company-logo"
              />
            </a>
            <a className="one-testimonial-ctn" href="https://brief.pl/rewolucja-w-inkubacji-gala-medbiz-innovations-program-i-green-innovations-challenge-za-nami/" target="_blank" rel="noreferrer nofollow">
              <p>{t`home.testimonial9`}</p>
              <StaticImage
                src="../../../images/home/testimonials/brief.png"
                alt="Brief napisali o nas"
                placeholder="none"
                loading="lazy"
                className="company-logo"
              />
            </a>
            <a className="one-testimonial-ctn" href="https://www.forbes.pl/opinie/fundacja-innovations-hub-wspiera-startupy-na-drodze-do-sukcesu/1gkffbg" target="_blank" rel="noreferrer nofollow">
              <p>{t`home.testimonial1`}</p>
              <StaticImage
                src="../../../images/home/testimonials/forbs.png"
                alt="Forbs o nas wspomniał"
                placeholder="none"
                loading="lazy"
                className="company-logo"
              />
            </a>
            <a className="one-testimonial-ctn" href="https://www.rp.pl/biznes/art8633701-powstaje-pierwszy-studencki-hub-innowacji" target="_blank" rel="noreferrer nofollow">
              <p>{t`home.testimonial2`}</p>
              <StaticImage
                src="../../../images/home/testimonials/rp.png"
                alt="Rzeczpospolita o nas wspomniała"
                placeholder="none"
                loading="lazy"
                className="company-logo"
              />
            </a>
            <a className="one-testimonial-ctn" href="https://businessinsider.com.pl/lifestyle/dolacz-do-drugiej-edycji-medbiz-innovations/b44ltq0" target="_blank" rel="noreferrer nofollow">
              <p>{t`home.testimonial3`}</p>
              <StaticImage
                src="../../../images/home/testimonials/bussines-insider.png"
                alt="Business insider o nas napisał"
                placeholder="none"
                loading="lazy"
                className="company-logo"
              />
            </a>
            <a className="one-testimonial-ctn" href="http://perspektywy.pl/portal/index.php?option=com_content&view=article&id=5688:medbiz-innovations&catid=24&Itemid=119" target="_blank" rel="noreferrer nofollow">
              <p>{t`home.testimonial4`}</p>
              <StaticImage
                src="../../../images/home/testimonials/perspektywy.png"
                alt="Perspektywy o nas wspomniały"
                placeholder="none"
                loading="lazy"
                className="company-logo"
              />
            </a>
            <a className="one-testimonial-ctn" href="https://biznesiekologia.com/fundacja-innovations-hub-nowa-era-startupow-ekoinnowacje-ksztaltuja-przyszlosc/" target="_blank" rel="noreferrer nofollow">
              <p>{t`home.testimonial5`}</p>
              <StaticImage
                src="../../../images/home/testimonials/biznes-ekologia.png"
                alt="Biznes Ekologia napisali o nas"
                placeholder="none"
                loading="lazy"
                className="company-logo"
              />
            </a>
            <a className="one-testimonial-ctn" href="https://liderzyinnowacyjnosci.com/fundacja-innovations-hub-liderem-inkubacji-startupow-w-polsce/" target="_blank" rel="noreferrer nofollow">
              <p>{t`home.testimonial6`}</p>
              <StaticImage
                src="../../../images/home/testimonials/liderzy-innowacyjnosci.png"
                alt="Liderzy Innowacyjności napisali o nas"
                placeholder="none"
                loading="lazy"
                className="company-logo"
              />
            </a>
          </Slider>
        </div>
      </div>
    </>
  );
}


const ctnStyle = {
  marginTop: '2rem',
  marginBottom: '2rem',
  paddingLeft: '0.75rem',
  paddingRight: '0.75rem',
  boxSizing: 'border-box',
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, width: '40px', height: '40px' }}
      onClick={onClick}
    >
      <img src={nextArrow} alt="Next arrow" style={{width: '40px', position: 'absolute', left: 0, overflow: 'hidden'}}/>
    </button>
  );
}

const Slider = ({children, className}) => {

  const settings = {
    dots: false,
    infinite: true,
    lazyLoad: true,
    speed: 600,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    pauseOnHover: true,
    swipeToSlide: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: null,
    responsive: [
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          nextArrow: null,
        }
      }
    ]
  };

  return (
    <div style={ctnStyle} className={className}>
      <SlickSlider {...settings}>
        {children}
      </SlickSlider>
    </div>
  );
}

export default Testimonials;
