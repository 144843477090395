import React from 'react';
import '../styles/inhub-three-perspectives.css'
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const InhubsThreePerspectives = () => {
  const {t} = useTranslation();

  return (
    <div style={{width: '100%', position: 'relative'}}>
      <StaticImage
        className="bg-motive"
        src="../../../images/home/three-perspectives/three-perspectives-bg-line.png"
        alt="Background line motive"
        placeholder="none"
        loading="eager"
      />

      <StaticImage
        className="bg-motive-mobile"
        src="../../../images/home/three-perspectives/mobile-bg.png"
        alt="Background line motive"
        placeholder="none"
        loading="eager"
      />

      <div className="container three-perspectives-ctn">
        <h3>{t`home.prospects-title`}</h3>
        <div>
          <div>
            <StaticImage
              src="../../../images/home/three-perspectives/tech_science.png"
              alt="Tech science perspective"
              placeholder="none"
              loading="eager"
              quality={100}
              className="mb-1rem"
            />
            <h4 className="mb-1rem">{t`home.prospects-1-title`}</h4>
            <p className="mb-1rem">{t`home.prospects-1-text`}</p>
          </div>
          <div>
            <StaticImage
              src="../../../images/home/three-perspectives/bussines.png"
              alt="Tech science perspective"
              placeholder="none"
              loading="eager"
              quality={100}
              className="mb-1rem"
            />
            <h4 className="mb-1rem">{t`home.prospects-2-title`}</h4>
            <p className="mb-1rem">{t`home.prospects-2-text`}</p>
          </div>
          <div>
            <StaticImage
              src="../../../images/home/three-perspectives/law.svg"
              alt="Tech science perspective"
              placeholder="none"
              loading="eager"
              quality={100}
              className="mb-1rem"
            />
            <h4 className="mb-1rem">{t`home.prospects-3-title`}</h4>
            <p className="mb-1rem">{t`home.prospects-3-text`}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InhubsThreePerspectives;
