import HomePage from '../views/new-home';
import { graphql } from 'gatsby';

export default HomePage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
