import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import '../styles/malinowski-testimonial.css'
import { NoBorderBtn } from './home-buttons';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const MalonowskiTestimonial = () => {
  const {t} = useTranslation();

  return (
    <div className="malinowski-ctn">
      <StaticImage
        src="../../../images/home/testimonials/strus-bg.jpg"
        alt="O sukcesie play.air"
        placeholder="blurred"
        loading="lazy"
      />
      <div className="inner-ctn">
        <p className="quotations">{t`home.testimonial-bartek`}</p>
        <p className="author"> {t`home.bartek`} <span>{t`home.bartek-owner`}</span></p>
        <NoBorderBtn to="/news/interviews">
          {t`home.read-ab-their-success`}
        </NoBorderBtn>
      </div>
    </div>
  );
}

export default MalonowskiTestimonial
